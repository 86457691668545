@layer reset, base, tokens, recipes, utilities;

@layer components {
    #__next {
        /* position: absolute;
        height: 100%;
        overflow-y: scroll; */
    }
}

.p-0 {
    padding: 0px;
}

.w-full {
    width: '100%';
}

.sticky {
    position: sticky;
}

.w-max {
    width: max-content;
}

.min-width-0 {
    min-width: 0px;
}

.inline-block {
    display: inline-block;
}

.gap-3 {
    gap: 0.75rem;
}

.min-height-0 {
    min-width: 0px;
}

.rounded-inherit {
    border-radius: inherit;
}

.text-center {
    text-align: center;
}

.text-color-light {
    color: var(--colors-primary11);
}

.text-color {
    color: var(--colors-text);
}

.left-0 {
    left: 0;
}
.right-0 {
    right: 0;
}

.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.flex {
    display: flex;
}
.flex-1 {
    flex: 1 1 0%;
}
.min-h-screen {
    min-height: 100vh;
}
.flex-col {
    flex-direction: column;
}
.justify-center {
    justify-content: center;
}
.overflow-hidden {
    overflow: hidden;
}
.items-center {
    align-items: center;
}
.justify-between {
    justify-content: space-between;
}
.rounded {
    border-radius: 0.25rem;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.h-full {
    height: 100%;
}
.w-full {
    width: 100%;
}
.h-screen {
    height: 100vh;
}
.gap-3 {
    gap: 0.75rem;
}
.overflow-scroll {
    overflow: scroll;
}
.opacity-0 {
    opacity: 0;
}
.grid {
    display: grid;
}
.place-content-center {
    place-content: center;
}
.border {
    border-width: 1px;
}
.items-center {
    align-items: center;
}
.text-center {
    text-align: center;
}

/* TO REMOVE, fix customkanban */
.bg-neutral-800\/0 {
    background-color: rgb(38 38 38 / 0);
}
.bg-neutral-800\/50 {
    background-color: rgb(38 38 38 / 0.5);
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.bg-neutral-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}
.text-neutral-50 {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity));
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.cursor-grab {
    cursor: grab;
}
.rounded {
    border-radius: 0.25rem;
}
.border {
    border-width: 1px;
}
.border-neutral-700 {
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity));
}
.bg-neutral-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}
.p-3 {
    padding: 0.75rem;
}
.active\:cursor-grabbing:active {
    cursor: grabbing;
}
.cursor-grabbing {
    cursor: grabbing;
}
.border {
    border-width: 1px;
}
.border-neutral-700 {
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity));
}
.bg-neutral-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-neutral-100 {
    --tw-text-opacity: 1;
    color: rgb(245 245 245 / var(--tw-text-opacity));
}
.my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
}
.h-0\.5 {
    height: 0.125rem;
}
.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.gap-1\.5 {
    gap: 0.375rem;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
